"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cowSwapper = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const ethers_1 = require("ethers");
const viem_1 = require("viem");
const filterAssetIdsBySellable_1 = require("./filterAssetIdsBySellable/filterAssetIdsBySellable");
const filterBuyAssetsBySellAssetId_1 = require("./filterBuyAssetsBySellAssetId/filterBuyAssetsBySellAssetId");
const constants_1 = require("./utils/constants");
const cowService_1 = require("./utils/cowService");
const helpers_1 = require("./utils/helpers/helpers");
exports.cowSwapper = {
    executeEvmMessage: async ({ chainId, orderToSign }, { signMessage }, config) => {
        const { chainReference } = (0, caip_1.fromChainId)(chainId);
        const signingDomain = Number(chainReference);
        const { appData, appDataHash } = orderToSign;
        // We need to construct orderDigest, sign it and send it to cowSwap API, in order to submit a trade
        // Some context about this : https://docs.cow.fi/tutorials/how-to-submit-orders-via-the-api/4.-signing-the-order
        // For more info, check hashOrder method implementation
        const orderDigest = (0, helpers_1.hashOrder)((0, helpers_1.domain)(signingDomain, constants_1.COW_SWAP_SETTLEMENT_ADDRESS), {
            ...orderToSign,
            // The order we're signing requires the appData to be a hash, not the stringified doc
            // However, the request we're making to *send* the order to the API requires both appData and appDataHash in their original form
            // see https://github.com/cowprotocol/cowswap/blob/a11703f4e93df0247c09d96afa93e13669a3c244/apps/cowswap-frontend/src/legacy/utils/trade.ts#L236
            appData: appDataHash,
        });
        // orderDigest should be an hex string here. All we need to do is pass it to signMessage/wallet.ethSignMessage and sign it
        const messageToSign = orderDigest;
        if (!(0, viem_1.isHex)(messageToSign))
            throw new Error('messageToSign is not an hex string');
        const signatureOrderDigest = await signMessage(messageToSign);
        // Passing the signature through split/join to normalize the `v` byte.
        // Some wallets do not pad it with `27`, which causes a signature failure
        // `splitSignature` pads it if needed, and `joinSignature` simply puts it back together
        const signature = ethers_1.ethers.Signature.from(ethers_1.ethers.Signature.from(signatureOrderDigest)).serialized;
        const maybeNetwork = (0, helpers_1.getCowswapNetwork)(chainId);
        if (maybeNetwork.isErr())
            throw maybeNetwork.unwrapErr();
        const network = maybeNetwork.unwrap();
        const maybeOrdersResponse = await cowService_1.cowService.post(`${config.REACT_APP_COWSWAP_BASE_URL}/${network}/api/v1/orders/`, {
            ...orderToSign,
            signingScheme: constants_1.SIGNING_SCHEME,
            signature,
            appData,
            appDataHash,
        });
        if (maybeOrdersResponse.isErr())
            throw maybeOrdersResponse.unwrapErr();
        const { data: orderUid } = maybeOrdersResponse.unwrap();
        return orderUid;
    },
    filterAssetIdsBySellable: (assets) => {
        return Promise.resolve((0, filterAssetIdsBySellable_1.filterAssetIdsBySellable)(assets));
    },
    filterBuyAssetsBySellAssetId: (input) => {
        return Promise.resolve((0, filterBuyAssetsBySellAssetId_1.filterBuyAssetsBySellAssetId)(input));
    },
};
