"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assertAndProcessMemo = void 0;
const utils_1 = require("@shapeshiftoss/utils");
const constants_1 = require("../../swappers/ThorchainSwapper/utils/constants");
function assertMemoHasPool(pool, memo) {
    if (!pool)
        throw new Error(`pool is required in memo: ${memo}`);
}
function assertMemoHasAsset(asset, memo) {
    if (!asset)
        throw new Error(`asset is required in memo: ${memo}`);
}
function assertMemoHasDestAddr(destAddr, memo) {
    if (!destAddr)
        throw new Error(`destination address is required in memo: ${memo}`);
}
function assertMemoHasAggregatorAddress(aggregatorAddr, memo) {
    if (!aggregatorAddr)
        throw new Error(`aggregator address is required in memo: ${memo}`);
}
function assertMemoHasFinalAssetContractAddress(finalAssetContractAddress, memo) {
    if (!finalAssetContractAddress)
        throw new Error(`final asset contract address is required in memo: ${memo}`);
}
function assertMemoHasPairedAddr(pairedAddr, memo) {
    if (!pairedAddr)
        throw new Error(`paired address is required in memo: ${memo}`);
}
function assertMemoHasLimit(limit, memo) {
    if (!limit)
        throw new Error(`limit is required in memo: ${memo}`);
}
function assertMemoHasFinalAssetLimit(finalAssetLimit, memo) {
    if (!finalAssetLimit)
        throw new Error(`final asset limit is required in memo: ${memo}`);
}
function assertMemoHasBasisPoints(basisPoints, memo) {
    if (!basisPoints)
        throw new Error(`basis points is required in memo: ${memo}`);
}
function assertMemoHasAction(action, memo) {
    if (!action)
        throw new Error(`action is required in memo: ${memo}`);
}
const assertIsValidLimit = (limit, memo) => {
    assertMemoHasLimit(limit, memo);
    const maybeStreamingSwap = limit.match(/\//g);
    const [lim, interval, quantity] = limit.split('/');
    if (maybeStreamingSwap) {
        if (!(lim && interval && quantity && maybeStreamingSwap.length === 3))
            throw new Error(`invalid streaming parameters in memo: ${memo}`);
        if (!(0, utils_1.bn)(lim).isInteger())
            throw new Error(`limit must be an integer in memo: ${memo}`);
        if (!(0, utils_1.bn)(interval).isInteger())
            throw new Error(`interval is required in memo: ${memo}`);
        if (!(0, utils_1.bn)(quantity).isInteger())
            throw new Error(`quantity is required in memo: ${memo}`);
    }
    if (!(0, utils_1.bn)(limit).gt(0))
        throw new Error(`positive limit is required in memo: ${memo}`);
};
const assertIsValidFinalAssetLimit = (finalAssetLimit, memo) => {
    assertMemoHasFinalAssetLimit(finalAssetLimit, memo);
    if (!(0, utils_1.bn)(finalAssetLimit).gt(0))
        throw new Error(`positive final asset limit is required in memo: ${memo}`);
    if (finalAssetLimit.length < 3)
        throw new Error(`positive final asset limit length should be at least 3 in memo: ${memo}`);
    if (finalAssetLimit.length > 19)
        throw new Error(`positive final asset limit length should be maximum 19 in memo: ${memo}`);
};
function assertIsValidBasisPoints(basisPoints, memo) {
    assertMemoHasBasisPoints(basisPoints, memo);
    if (!(0, utils_1.bn)(basisPoints).isInteger())
        throw new Error(`basis points must be an integer in memo: ${memo}`);
    if ((0, utils_1.bn)(basisPoints).lt(0) || (0, utils_1.bn)(basisPoints).gt(10000))
        throw new Error(`basis points must be between 0-10000 in memo: ${memo}`);
}
/**
 * asserts memo is valid and processes the memo to ensure our affiliate code is always present
 */
const assertAndProcessMemo = (memo) => {
    const [action] = memo.split(':');
    assertMemoHasAction(action, memo);
    switch (action.toLowerCase()) {
        case 'swap':
        case '=':
        case 's': {
            // SWAP:ASSET:DESTADDR:LIM/INTERVAL/QUANTITY:AFFILIATE:FEE
            const [_action, asset, destAddr, limit, , fee, aggregatorAddress, finalAssetAddress, finalAssetAmountOut,] = memo.split(':');
            assertMemoHasAsset(asset, memo);
            assertMemoHasDestAddr(destAddr, memo);
            assertIsValidLimit(limit, memo);
            // SWAP:ASSET:DESTADDR:LIM:AFFILIATE:FEE:DEXAggregatorAddr:FinalTokenAddr:MinAmountOut|
            const maybeSwapOutParts = (() => {
                if (aggregatorAddress || finalAssetAddress || finalAssetAmountOut) {
                    assertMemoHasAggregatorAddress(aggregatorAddress, memo);
                    assertMemoHasFinalAssetContractAddress(finalAssetAddress, memo);
                    assertIsValidFinalAssetLimit(finalAssetAmountOut, memo);
                    return `:${aggregatorAddress}:${finalAssetAddress}:${finalAssetAmountOut}`;
                }
                return '';
            })();
            return `${_action}:${asset}:${destAddr}:${limit}:${constants_1.THORCHAIN_AFFILIATE_NAME}:${fee || 0}${maybeSwapOutParts}`;
        }
        case 'add':
        case '+':
        case 'a': {
            const [_action, pool, maybePairedAddr, , fee] = memo.split(':');
            assertMemoHasPool(pool, memo);
            // Add Liquidity - ADD:POOL:PAIREDADDR:AFFILIATE:FEE
            if (pool.includes('.')) {
                if (maybePairedAddr)
                    assertMemoHasPairedAddr(maybePairedAddr, memo);
                return `${_action}:${pool}:${maybePairedAddr ?? ''}:${constants_1.THORCHAIN_AFFILIATE_NAME}:${fee || 0}`;
            }
            // Deposit Savers - ADD:POOL::AFFILIATE:FEE
            if (pool.includes('/')) {
                if (maybePairedAddr)
                    throw new Error('paired address is not supported for saver deposit');
                return `${_action}:${pool}::${constants_1.THORCHAIN_AFFILIATE_NAME}:${fee || 0}`;
            }
            throw new Error(`invalid pool in memo: ${memo}`);
        }
        case 'withdraw':
        case '-':
        case 'wd': {
            const [_action, pool, basisPoints, maybeAsset] = memo.split(':');
            assertMemoHasPool(pool, memo);
            assertMemoHasBasisPoints(basisPoints, memo);
            // Withdraw Liquidity - WITHDRAW:POOL:BASISPOINTS:ASSET
            if (pool.includes('.')) {
                if (maybeAsset)
                    assertMemoHasAsset(maybeAsset, memo);
                assertIsValidBasisPoints(basisPoints, memo);
                return `${_action}:${pool}:${basisPoints}:${maybeAsset ?? ''}:${constants_1.THORCHAIN_AFFILIATE_NAME}:0`;
            }
            // Withdraw Savers - WITHDRAW:POOL:BASISPOINTS
            if (pool.includes('/')) {
                if (maybeAsset)
                    throw new Error('asset is not supported for savers withdraw');
                assertIsValidBasisPoints(basisPoints, memo);
                return `${_action}:${pool}:${basisPoints}::${constants_1.THORCHAIN_AFFILIATE_NAME}:0`;
            }
            throw new Error(`invalid pool in memo: ${memo}`);
        }
        // LOAN+:ASSET:DESTADDR:MINOUT:AFFILIATE:FEE
        case '$+':
        case 'loan+': {
            const [_action, asset, destAddr, minOut, , fee] = memo.split(':');
            assertMemoHasAsset(asset, memo);
            assertMemoHasDestAddr(destAddr, memo);
            return `${_action}:${asset}:${destAddr}:${minOut ?? ''}:${constants_1.THORCHAIN_AFFILIATE_NAME}:${fee || 0}`;
        }
        // LOAN-:ASSET:DESTADDR:MINOUT
        case '$-':
        case 'loan-': {
            const [_action, asset, destAddr, minOut] = memo.split(':');
            assertMemoHasAsset(asset, memo);
            assertMemoHasDestAddr(destAddr, memo);
            return `${_action}:${asset}:${destAddr}:${minOut ?? ''}:${constants_1.THORCHAIN_AFFILIATE_NAME}:0`;
        }
        default:
            throw new Error(`unsupported memo: ${memo}`);
    }
};
exports.assertAndProcessMemo = assertAndProcessMemo;
