"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lifiTokenToAsset = void 0;
const lifiChainIdToChainId_1 = require("../lifiChainIdtoChainId/lifiChainIdToChainId");
const lifiTokenToAssetId_1 = require("../lifiTokenToAssetId/lifiTokenToAssetId");
const lifiTokenToAsset = (lifiToken, assets) => {
    const assetId = (0, lifiTokenToAssetId_1.lifiTokenToAssetId)(lifiToken);
    const maybeAsset = assets[assetId];
    if (maybeAsset)
        return maybeAsset;
    // asset not known by shapeshift
    // create a placeholder asset using the data we have
    return {
        assetId,
        chainId: (0, lifiChainIdToChainId_1.lifiChainIdToChainId)(lifiToken.chainId),
        name: lifiToken.name,
        precision: lifiToken.decimals,
        symbol: lifiToken.symbol,
        color: '#000000',
        icon: lifiToken.logoURI ?? '',
        explorer: '',
        explorerTxLink: '',
        explorerAddressLink: '',
    };
};
exports.lifiTokenToAsset = lifiTokenToAsset;
